<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Escolha uma categoria abaixo para editar ou excluir.
        </p>
        <v-btn
          v-if="categoriaCriar"
          small
          depressed
          color="fmq_gray"
          dark
          to="/categorias-criar"
          >Nova Categoria</v-btn
        >
      </div>
      <v-card elevation="1" :loading="loading">
        <div class="caption font-weight-bold pa-4 fmq_gray--text">
          Nome da Categoria
        </div>

        <div class="grey lighten-3 px-4 py-1 d-flex align-center">
          <v-text-field
            v-model="categoriaNome"
            placeholder="Digite o nome da categoria"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @click="open = true"
          >
          </v-text-field>
          <v-switch
          class="mt-0 pa-0 ml-4"
            v-model="open"
            inset
            dense
            label="Expandir todas"
            :hide-details="true"
          ></v-switch>
        </div>
        <CategoriaLista
          v-if="!loading"
          :categorias="categorias"
          :search="categoriaNome"
          :open="open"
          @excluir="excluirModal"
        />
      </v-card>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Categoria excluida com sucesso"
      @close="
        getCategorias();
        success = false;
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import CategoriaLista from "@/components/categorias/CategoriaLista.vue";
import { todasCategorias, excluirCategoria } from "@/services/categorias";
export default {
  name: "Categorias",
  components: { CategoriaLista },
  data: () => ({
    breadcrumbs: [
      {
        text: "Categorias",
        disabled: true,
        to: "",
      },
    ],
    loading: false,
    categorias: [],
    totalCategorias: null,
    dialog: false,
    dialogMessage: null,
    categoriaId: null,
    error: false,
    success: false,
    messageError: null,
    categoriaNome: "",
    open: true,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getCategorias();
  },
  methods: {
    async getCategorias() {
      this.categorias = [];
      try {
        this.loading = true;
        const resp = await todasCategorias();
        this.categorias = resp.data;
        this.totalCategorias = resp.data.total;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirCategoria(this.categoriaId).then(() => {
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.nome}?`;
      this.dialog = true;
      this.categoriaId = item.id;
    },
  },
  computed: {
    categoriaCriar() {
      return this.$store.state.user.data.permissoes.includes(
        "categoria.criar"
      );
    },
  },
};
</script>

<style lang="scss">
.v-treeview .v-treeview-node .v-treeview-node__root {
  border-bottom: 1px solid #eeeeee;
}
</style>
