<template>
  <v-treeview
    :items="categorias"
    item-text="nome"
    item-children="subcategorias"
    :hoverable="true"
    :open-on-click="true"
    :search="search"
    item-key="id"
    :open-all="open"
    ref="mytreeview"
  >
    <template v-slot:[`append`]="{ item }">
      <v-menu offset-y v-if="categoriaEditar || categoriaExcluir">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed color="fmq_gray" dark v-bind="attrs" v-on="on">
            Opções <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="gotToCategorias(item.id)" v-if="categoriaEditar">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('excluir', item)" v-if="categoriaExcluir">
            <v-list-item-title>Excluir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-treeview>
</template>

<script>
export default {
  name: "CategoriaLista",
  props: {
    categorias: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    openAll: true,
  }),
  methods: {
    gotToCategorias(id) {
      this.$router.push({ name: "CategoriasEditar", params: { id: id } });
    },
  },
  computed: {
    categoriaEditar() {
      return this.$store.state.user.data.permissoes.includes(
        "categoria.editar"
      );
    },
    categoriaExcluir() {
      return this.$store.state.user.data.permissoes.includes(
        "categoria.excluir"
      );
    },
  },
  watch: {
    open() {
      this.openAll = !this.openAll;
      this.$refs.mytreeview.updateAll(this.openAll);
    },
  },
};
</script>

<style lang="scss"></style>
